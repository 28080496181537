/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRegistration = /* GraphQL */ `
  subscription OnCreateRegistration {
    onCreateRegistration {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRegistration = /* GraphQL */ `
  subscription OnUpdateRegistration {
    onUpdateRegistration {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRegistration = /* GraphQL */ `
  subscription OnDeleteRegistration {
    onDeleteRegistration {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCredentials = /* GraphQL */ `
  subscription OnCreateCredentials {
    onCreateCredentials {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCredentials = /* GraphQL */ `
  subscription OnUpdateCredentials {
    onUpdateCredentials {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCredentials = /* GraphQL */ `
  subscription OnDeleteCredentials {
    onDeleteCredentials {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeshSettings = /* GraphQL */ `
  subscription OnCreateMeshSettings {
    onCreateMeshSettings {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeshSettings = /* GraphQL */ `
  subscription OnUpdateMeshSettings {
    onUpdateMeshSettings {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeshSettings = /* GraphQL */ `
  subscription OnDeleteMeshSettings {
    onDeleteMeshSettings {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateResetCodes = /* GraphQL */ `
  subscription OnCreateResetCodes {
    onCreateResetCodes {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResetCodes = /* GraphQL */ `
  subscription OnUpdateResetCodes {
    onUpdateResetCodes {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResetCodes = /* GraphQL */ `
  subscription OnDeleteResetCodes {
    onDeleteResetCodes {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
