// import { composeWithDevTools } from 'remote-redux-devtools';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
import promise from '../utils/promise';

const VERSION_REDUCER_KEY = 'app';

export default function configureStore() {

    // const composeEnhancers = composeWithDevTools({ name: 'grow-web', realtime: true });

    const enhancer = compose(
        applyMiddleware(thunk, promise)
    );


    const store = createStore(reducer, enhancer);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./reducer').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
