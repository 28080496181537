import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 300;

const styles = makeStyles(theme => ({
    authHeader: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    authImage:{
      marginBottom: theme.spacing(4)
    },
    grow: {
      flexGrow: 1
    },
    toolbarImage: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center'
    },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px) !important`,
            marginLeft: drawerWidth,
        },
        justifyContent: 'center'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listItemRoot: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        }
    },
    listItemSelected: {
        color: theme.palette.primary.main, //'#98cb51',
        backgroundColor: 'transparent !important'

    },
    sidebar: {
        paddingTop: '0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12);'
    },
    listItemTextSelected: {
        fontWeight: '700',
        fontSize: '1.2rem'
    }
}));

export default styles
