import * as constants from '../utils/constants'
import LightBridge from '../utils/lightBridge';


export function setInitScan(initScan) {
    return function (dispatch) {
        return dispatch({
            type: constants.SET_INIT_SCAN,
            action: initScan
        });
    };
}

export function addGroup(group) {
    return function (dispatch) {
        return dispatch({
            type: constants.ADD_GROUP,
            action: group
        });
    };
}

export function removeGroup(id) {
    return function (dispatch) {
        return dispatch({
            type: constants.REMOVE_GROUP,
            action: id
        });
    };
}

export function updateGroup(group) {
    return function (dispatch) {
        return dispatch({
            type: constants.UPDATE_GROUP,
            action: group
        });
    };
}

export function addLight(light) {
    return function (dispatch) {
        console.log("ADD LIGHT: ", light);
        return dispatch({
            type: constants.ADD_LIGHT,
            action: light
        });
    };
}

export function updateLight(light) {
    return function (dispatch) {
        return dispatch({
            type: constants.UPDATE_LIGHT,
            action: light
        });
    };
}

export function removeLight(id) {
    return function (dispatch) {
        return dispatch({
            type: constants.REMOVE_LIGHT,
            action: id
        });
    };
}

export function clearLights() {
    return function (dispatch) {
        return dispatch({
            type: constants.CLEAR_LIGHTS
        });
    };
}

export function renameLight(id, name) {
    return function (dispatch) {
        return dispatch({
            type: constants.RENAME_LIGHT,
            action: {id, name}
        });
    };
}

export function toggleGroup(id, state) {
    return function (dispatch) {

        if (state){
            LightBridge.turnOnGroupWithAddress(id);
        }else{
            LightBridge.turnOffGroupWithAddress(id);

        }
        return dispatch({
            type: constants.TOGGLE_GROUP,
            action: {id, state}
        });

    };
}

export function toggleLight(id, state) {
    return function (dispatch) {

        if (state){
            LightBridge.turnOnWithAddress(id);
        }else{
            LightBridge.turnOffWithAddress(id);
        }
        return dispatch({
            type: constants.TOGGLE_LIGHT,
            action: {id, state}
        });

    };
}

export function toggleRoutine(id, state) {
    return function (dispatch) {
        return dispatch({
            type: constants.TOGGLE_ROUTINE,
            action: {id, state}
        });
    };
}

export function addRoutine(routine) {
    return function (dispatch) {
        return dispatch({
            type: constants.ADD_ROUTINE,
            action: routine
        });
    };
}

export function editRoutine(routine) {
    return function (dispatch) {
        return dispatch({
            type: constants.EDIT_ROUTINE,
            action: routine
        });
    };
}

export function removeRoutine(id) {
    return function (dispatch) {
        return dispatch({
            type: constants.REMOVE_ROUTINE,
            action: id
        });

    };
}

export function renameRoutine(id, name) {
    return function (dispatch) {
        return dispatch({
            type: constants.RENAME_ROUTINE,
            action: {id, name}
        });

    };
}

export function toggleAll(value) {
    return function (dispatch) {
        if (value){
            LightBridge.turnAllOn();
        }else{
            LightBridge.turnAllOff();
        }
        return dispatch({
            type: constants.TOGGLE_ALL,
            action: value
        });
    };
}

export function setLights(lights){

}

export function onNotify(data){
    console.log("RECEIVED MESSAGE: ", data.value.opcode, data.value.src, data.value.params);
    return function (dispatch){
        const result = LightBridge.parse(data.value.opcode, data.value.src, data.value.params);

        switch (result.tag) {
            case constants.GET_DEVICE_STATUS:
                return dispatch({
                    type: constants.UPDATE_LIGHT,
                    action: result.data
                });
            case constants.GET_MESH_DEVICE_LIST:
                return dispatch({
                    type: constants.ADD_LIGHT,
                    action: result.data
                });
            case constants.GET_MESH_STATUS:
                return dispatch({
                    type: constants.ADD_LIGHTS,
                    action: result.data
                });
                break;
        }

    }
}
