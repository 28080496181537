import React, {forwardRef} from 'react';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import VpnKey from '@material-ui/icons/VpnKey';

import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import * as mutations from '../../graphql/mutations';
import moment from "moment";
import axios from 'axios';
import _ from 'lodash';
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
    VpnKey: forwardRef((props, ref) => <VpnKey {...props} ref={ref}/>)
};

function Codes() {

    const [meshSettings, setMeshSettings] = React.useState([])
    React.useEffect(() => {

        async function fetchMeshSettings() {
            const meshSettings = await API.graphql({query: queries.listMeshSettingss});
            const resetCodes = await API.graphql({query: queries.listResetCodess});
            var meshes = meshSettings.data.listMeshSettingss.items;
            var codes = _.filter(resetCodes.data.listResetCodess.items, {'_deleted': null});
            console.log(resetCodes.data.listResetCodess.items, codes)
            for (var code of codes){
                var i = _.findIndex(meshes, {'email': code.email});
                meshes[i].code = code.code;
                meshes[i].expiration = code.expiration;
            };
            setMeshSettings(meshes);
        }

        fetchMeshSettings();

        const subscription = API.graphql(
            graphqlOperation(subscriptions.onDeleteResetCodes)
        ).subscribe({
            next: ({ provider, value }) => {
                console.log(value.data.onDeleteResetCodes.email);
                fetchMeshSettings();
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    const updateRow = (row) => {

        var meshes = _.clone(meshSettings)
        var i = _.findIndex(meshes, {'email': row.email});
        console.log(meshes, row, i)
        if (i > -1){
            meshes[i].code = row.code;
            meshes[i].expiration = row.expiration;
            setMeshSettings(meshes);
        }

    };

    return (
        <div>
            <h2>Reset Codes</h2>
            <MaterialTable
                icons={tableIcons}
                columns={[
                    {title: 'Name', field: 'name'},
                    {title: 'Email', field: 'email'},
                    {title: 'Created On', field: 'createdAt', type: 'datetime'},
                    {title: 'Code', field: 'code'},
                    {title: 'Expires On', field: 'expiration', type: 'date'}
                ]}
                data={meshSettings}
                title=""
                actions={[{
                    icon: tableIcons.VpnKey, tooltip: 'Create Code', onClick: (event, rowData) => {
                        const seq = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
                        const resetCode = {
                            email: rowData.email,
                            code: seq,
                            expiration: moment().add(30, 'd').toISOString()
                        }

                        API.graphql({query: mutations.createResetCodes, variables: {input: resetCode}}).then(()=>{
                            updateRow(resetCode);
                            alert("Reset Code Created!");
                        })
                    }
                }]}
                options={{actionsColumnIndex: 5, pageSize: 50}}
            />
        </div>


    )
}

export default Codes
