import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup';
import AlarmIcon from '@material-ui/icons/Alarm';
import Dashboard from '../views/dashboard'
import Lights from '../views/lights'
import Groups from '../views/groups'
import Routines from '../views/routines'
import Settings from '../views/settings'
import Codes from '../views/codes'

function onClick(e, item) {
    console.log(item);
    // window.alert(JSON.stringify(item, null, 2));
}

const routes = [
    { name: "home", label: "Dashboard", Icon: HomeIcon, component: Dashboard, path: "/", exact: true },
    // { name: "lights", label: "Lights", Icon: WbIncandescentIcon, component: Lights, path: "/lights"},
    // { name: "groups", label: "Groups", Icon: SpeakerGroupIcon, component: Groups, path: "/groups"},
    // { name: "routines", label: "Routines", Icon: AlarmIcon, component: Routines, path: "/routines"},
    // { name: "settings", label: "Settings", Icon: SettingsIcon, component: Settings, path: "/settings"},
    { name: "codes", label: "Reset Codes", Icon: SettingsIcon, component: Codes, path: "/codes"},
    // {
    //     name: "Lights",
    //     label: "Lights",
    //     icon: ReceiptIcon,
    //     component: Lights
    // },
    // "divider",
    // {
    //     name: "settings",
    //     label: "Settings",
    //     icon: SettingsIcon,
    // }
];

export default routes
