import React from "react";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import Divider from "@material-ui/core/Divider/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse/index";
import {ListItemIcon, ListItemText} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import styles from '../../utils/styles';
function SidebarItem({ depthStep = 10, depth = 0, expanded, item, ...rest }) {
    const [collapsed, setCollapsed] = React.useState(true);
    const { label, items, Icon, onClick: onClickProp } = item;
    const history = useHistory();
    const location = useLocation();
    const classes = styles();
    const selected = location.pathname === item.path;
    function toggleCollapse() {
        setCollapsed(prevValue => !prevValue);
    }

    function onClick(e) {
        if (Array.isArray(items)) {
            toggleCollapse();
        }
        if (onClickProp) {
            onClickProp(e, item);
        } else {
            history.push(item.path)
        }
    }

    let expandIcon;

    if (Array.isArray(items) && items.length) {
        expandIcon = !collapsed ? (
            <ExpandLessIcon
                className={
                    "sidebar-item-expand-arrow" + " sidebar-item-expand-arrow-expanded"
                }
            />
        ) : (
            <ExpandMoreIcon className="sidebar-item-expand-arrow" />
        );
    }

    return (
        <>
            <ListItem
                onClick={onClick}
                button
                divider
                selected={selected}
                classes={{
                    root: classes.listItemRoot,
                    selected: classes.listItemSelected
                }}
                {...rest}
            >

                {Icon && <ListItemIcon><Icon color={selected ? 'primary' : 'inherit'}/></ListItemIcon>}
                <ListItemText classes={{
                    primary: selected && classes.listItemTextSelected
                }} primary={label}/>
                {expandIcon}
            </ListItem>
            <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                {Array.isArray(items) ? (
                    <List disablePadding dense>
                        {items.map((subItem, index) => (
                            <React.Fragment key={`${subItem.name}${index}`}>
                                {subItem === "divider" ? (
                                    <Divider style={{ margin: "6px 0" }} />
                                ) : (
                                    <SidebarItem
                                        depth={depth + 1}
                                        depthStep={depthStep}
                                        item={subItem}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                ) : null}
            </Collapse>
        </>
    );
}

function Sidebar({ items, depthStep, depth, expanded, toggleDrawer }) {
    const classes = styles();
    return (
        <div onClick={()=>{toggleDrawer && toggleDrawer()}}>
            <div className={classes.toolbar}/>
            <List className={classes.sidebar}>
                {items.map((sidebarItem, index) => (
                    <React.Fragment key={`${sidebarItem.name}${index}`}>
                        {sidebarItem === "divider" ? (
                            <Divider style={{ margin: "6px 0" }} />
                        ) : (
                            <SidebarItem
                                depthStep={depthStep}
                                depth={depth}
                                expanded={expanded}
                                item={sidebarItem}
                            />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
}

export default Sidebar;
