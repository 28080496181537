/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncRegistrations = /* GraphQL */ `
  query SyncRegistrations(
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRegistration = /* GraphQL */ `
  query GetRegistration($id: ID!) {
    getRegistration(id: $id) {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listRegistrations = /* GraphQL */ `
  query ListRegistrations(
    $filter: ModelRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCredentials = /* GraphQL */ `
  query SyncCredentials(
    $filter: ModelCredentialsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCredentials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCredentials = /* GraphQL */ `
  query GetCredentials($id: ID!) {
    getCredentials(id: $id) {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCredentialss = /* GraphQL */ `
  query ListCredentialss(
    $filter: ModelCredentialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredentialss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeshSettings = /* GraphQL */ `
  query SyncMeshSettings(
    $filter: ModelMeshSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeshSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        company
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeshSettings = /* GraphQL */ `
  query GetMeshSettings($id: ID!) {
    getMeshSettings(id: $id) {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMeshSettingss = /* GraphQL */ `
  query ListMeshSettingss(
    $filter: ModelMeshSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeshSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        company
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncResetCodes = /* GraphQL */ `
  query SyncResetCodes(
    $filter: ModelResetCodesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncResetCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        code
        expiration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getResetCodes = /* GraphQL */ `
  query GetResetCodes($id: ID!) {
    getResetCodes(id: $id) {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listResetCodess = /* GraphQL */ `
  query ListResetCodess(
    $filter: ModelResetCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResetCodess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        code
        expiration
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
