/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://sucq757nafadpgk4wzm4bgyzsu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bjjf6fso7nf3xo3zbvufjjle2q",
    "aws_cognito_identity_pool_id": "us-east-2:ca706c64-cddb-44a4-b0fc-30a262fdf379",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_nhDuk8Bzy",
    "aws_user_pools_web_client_id": "598483qn8crkeph7nnhmq8bbtk",
    "oauth": {
        "domain": "urbarnapp-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://urbarn.io",
        "redirectSignOut": "https://urbarn.io",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
