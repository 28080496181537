import React, {useState} from 'react'
import {Card, CardContent, Grid, Switch, CardActionArea} from "@material-ui/core";
import cx from "clsx";
import BrandCardHeader from "@mui-treasury/components/cardHeader/brand";
import TextInfoContent from "@mui-treasury/components/content/textInfo";
import {makeStyles} from "@material-ui/core";
import {useN03TextInfoContentStyles} from "@mui-treasury/styles/textInfoContent/n03";
import {useLightTopShadowStyles} from "@mui-treasury/styles/shadow/lightTop";
import LightBridge from "../../utils/lightBridge";
import Sidebar from "../sidebar";
import routes from "../../utils/routes";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 343,
        borderRadius: 20,
    },
    content: {
        paddingTop: 0,
        paddingLeft: 24,
        paddingRight: 24,
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        marginBottom: '0'
    },
    grid: {
        flexGrow: 1
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    actionArea: {
        backgroundColor: 'transparent'
    }
}));

function LightCard({light, index, onSelect}){

    const styles = useN03TextInfoContentStyles();
    const shadowStyles = useLightTopShadowStyles();
    const classes = useStyles();


    const [state, setState] = React.useState({
        checked: light.status !== 0 && light.brightness > 0,
    });

    const handleChange = event => {
        setState({ checked: event.target.checked });
        if (event.target.checked){
            LightBridge.turnOnWithAddress(light.id);
        }else{
            LightBridge.turnOffWithAddress(light.id);
        }
    };

    return (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
            <Card className={cx(classes.root, classes.root)}>
                <CardActionArea onClick={()=>{onSelect(light)}} classes={{
                    focusHighlight: classes.actionArea
                }}>
                    <BrandCardHeader
                        image={
                            'https://getdrawings.com/free-icon-bw/light-bulb-icon-15.png'
                        }
                        extra={'7 minutes'}
                        classes={{divider: classes.divider}}
                    />
                </CardActionArea>
                <div className={classes.controls}>
                    <Switch
                        checked={state.checked}
                        onChange={handleChange}
                        color={'primary'}
                        name="checked"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <CardActionArea onClick={()=>{onSelect(light)}} classes={{
                    focusHighlight: classes.actionArea
                }}>
                <CardContent className={classes.content}>
                        <TextInfoContent
                            classes={styles}
                            overline={'Light'}
                            heading={
                                <div>
                                    Light {light.id}
                                </div>
                            }
                            body={
                                'r: 1'
                            }
                        />

                </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default LightCard
