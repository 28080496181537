//ACTIONS
export const SET_INIT_SCAN = 'SET_INIT_SCAN';
export const ADD_GROUP = 'ADD_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_LIGHT = 'ADD_LIGHT';
export const ADD_LIGHTS = 'ADD_LIGHTS';
export const ADD_PERSISTED_LIGHT = 'ADD_PERSISTED_LIGHT';
export const UPDATE_LIGHT = 'UPDATE_LIGHT';
export const REMOVE_LIGHT = 'REMOVE_LIGHT';
export const CLEAR_LIGHTS = 'CLEAR_LIGHTS';
export const RENAME_LIGHT = 'RENAME_LIGHT';
export const TOGGLE_LIGHT = 'TOGGLE_LIGHT';
export const TOGGLE_GROUP = 'TOGGLE_GROUP';
export const TOGGLE_ROUTINE = 'TOGGLE_ROUTINE';
export const ADD_ROUTINE = 'ADD_ROUTINE';
export const EDIT_ROUTINE = 'EDIT_ROUTINE';
export const REMOVE_ROUTINE = 'REMOVE_ROUTINE';
export const RENAME_ROUTINE = 'RENAME_ROUTINE';
export const TOGGLE_ALL = 'TOGGLE_ALL';
export const FADE_ON = 'FADE_ON';
export const FADE_OFF = 'FADE_OFF';
export const GROUP_FADE_ON = 'GROUP_FADE_ON';
export const GROUP_FADE_OFF = 'GROUP_FADE_OFF';
export const ON_NOTIFY = 'ON_NOTIFY';

//IoT topics
export const LIGHT_NOTIFY = 'light/notify';
export const LIGHT_COMMAND = 'light/command';
export const LIGHT_ON_NOTIFY = 'light/on_notify';

//ON NOTIFY TAGS
export const GET_DEVICE_STATUS = 'GET_DEVICE_STATUS';
export const GET_MESH_DEVICE_LIST = 'GET_MESH_DEVICE_LIST';
export const GET_MESH_STATUS = 'GET_MESH_STATUS';
