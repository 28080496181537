import { PubSub } from 'aws-amplify';
import * as constants from './constants';
import common from './common';
import _ from 'lodash';

function hexlify(value) {
    return value.toString(16).padStart(2, '0')
}

class LightBridge {
    constructor(){

    }
    static getStatus(){
        console.log("SENDING COMMAND: getStatus");
        PubSub.publish(constants.LIGHT_NOTIFY, {
            "message": "status"
        });
    }
    static getDevices(){

        console.log("SENDING COMMAND: getDevices");
        PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 224,
            "address": 65535,
            "params": "ff-ff-01-16"
        });
    }
    static getDeviceStatus(id){
        console.log("SENDING COMMAND: getDeviceStatus");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 218,
            "address": id,
            "params": "16"
        });
    }

    static turnOnGroupWithAddress(groupId){
        console.log("SENDING COMMAND: turnOnGroupWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 208,
            "address": groupId,
            "params": "01-00-00"
        });
    }

    static turnOffGroupWithAddress(groupId){
        console.log("SENDING COMMAND: turnOffGroupWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 208,
            "address": groupId,
            "params": "00-00-00"
        });
    }

    static turnOnWithAddress(lightId){
        console.log("SENDING COMMAND: turnOnWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 208,
            "address": lightId,
            "params": "01-00-00"
        });
    }

    static turnOffWithAddress(lightId){
        console.log("SENDING COMMAND: turnOffWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 208,
            "address": lightId,
            "params": "00-00-00"
        })
    }

    static updateBrightnessWithAddress(lightId, brightness){
        console.log("SENDING COMMAND: updateBrightnessWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 210,
            "address": lightId,
            "params": hexlify(brightness)
        })
    }

    static updateChannelWithAddress(lightId, r1, b1, w, r2, b2){
        console.log("SENDING COMMAND: updateChannelWithAddress");
        return PubSub.publish(constants.LIGHT_COMMAND, {
            "opcode": 226,
            "address": lightId,
            "params": `09-${hexlify(w)}-${hexlify(r2)}-${hexlify(b2)}-${hexlify(r1)}-${hexlify(b1)}`
        })
    }

    static turnAllOn(){

    }

    static turnAllOff(){

    }

    static parse(opcode, src, params){
        const data = _.map(params.split("-"), hex=>parseInt(hex, 16));
        let result = {};
        let light = {};
        switch (opcode) {
            case 219:
                result.tag = constants.GET_DEVICE_STATUS;
                light.id = src;
                light.r1 = (data[3] & 0xFF);
                light.b1 = (data[4] & 0xFF);
                light.w = (data[0] & 0xFF);
                light.r2 = (data[1] & 0xFF);
                light.b2 = (data[2] & 0xFF);
                light.brightness = (data[6] & 0xFF);
                result.data = light;
                break;
            case 220:
                let meshAddress, status, brightness, reserve;
                let position = 0;
                const packetSize = 4;
                const length = data.length;
                let lights = [];
                const tag = constants.GET_MESH_STATUS;
                while ((position + packetSize) < length){

                    meshAddress = data[position++];
                    status = data[position++];
                    brightness = data[position++];
                    reserve = data[position++];

                    meshAddress = meshAddress & 0xFF;
                    if (meshAddress == 0x00
                        || (meshAddress == 0xFF && brightness == 0xFF))
                        break;

                    lights.push({
                        id: meshAddress,
                        brightness,
                        status
                    });

                }

                result = { data: lights, tag }
                break;
            case 225:
                light.id = (data[0] & 0xFF) | (data[1] & 0xFF << 8);
                light.macAddress = params.split("-").slice(2, 8).reverse().join(":");
                result.data = light;
                result.tag = constants.GET_MESH_DEVICE_LIST;
                break;
        }

        return result;
    }
}


export default LightBridge
