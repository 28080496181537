import React, {useEffect, useState} from 'react';
// import lights from '../../utils/data/lights'
import { Search } from '@material-ui/icons';
import {makeStyles, useTheme, fade, useMediaQuery, Slide, DialogTitle, DialogContent,IconButton, Typography, Slider} from '@material-ui/core';
import { Grid, InputBase, Dialog, Hidden } from '@material-ui/core';
import { Close } from "@material-ui/icons";
import LightCard from '../../components/lightCard'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as actions from '../../redux/actions'
import {PubSub} from "aws-amplify";
import * as constants from "../../utils/constants";
import LightBridge from "../../utils/lightBridge";
import { CircleSlider } from "react-circle-slider";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    grid: {
        flexGrow: 1
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: theme.shape.borders,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginBottom: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    sliderRoot: {
        height: 300,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    powerRoot: {
        padding: 8,
        marginBottom: 8,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
}));

function Lights({lights}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedLight, setSelectedLight] = useState({});
    const [slider, setSlider] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const handleDialogToggle = () => {
        setDialogOpen(!dialogOpen);
    };

    const onSelect = (light) => {
        const selected = _.find(lights, (l)=>{return l.id === light.id});
        setDialogOpen(true);
        setSlider(selected.brightness);
        setSelectedLight(selected);
        LightBridge.getDeviceStatus(light.id);
    };

    const handleSliderChange = _.debounce(value => {
        LightBridge.updateBrightnessWithAddress(selectedLight.id, value);
    }, 200);

    const handleChannelChange = name => _.debounce((event, value) => {
        let { r1, b1, w, r2, b2 } = selectedLight;
        selectedLight[name] = value;
        LightBridge.updateChannelWithAddress(selectedLight.id, r1, b1, w, r2, b2);
    }, 200);

    useEffect(()=>{
       LightBridge.getDevices();
    }, []);

    // useEffect(()=>{
    //     if (!selectedLight && !selectedLight.id){
    //         const selected = _.find(lights, (l)=>{return l.id === selectedLight.id});
    //         console.log(selected);
    //         setSelectedLight(selected);
    //     }
    // }, [lights]);

    return (
        <div className={classes.grid}>
            <div className={classes.search}>
                <div className={classes.searchIcon} >
                    <Search />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
            {lights.map((l, index)=>{
                return (
                    <LightCard light={l} key={index} onSelect={onSelect}/>
                )
            })}
            </Grid>
            <Dialog fullScreen={fullScreen} open={dialogOpen} onClose={handleDialogToggle}>
                <DialogTitle className={classes.dialogTitle}>
                    Light {selectedLight.id}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogToggle}>
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers className={classes.dialogContent}>
                    <Typography gutterBottom>
                       Use the controls below to adjust the settings of the light.
                    </Typography>
                    <div className={classes.powerRoot}>
                        <CircleSlider
                            size={240}
                            knobRadius={15}
                            progressWidth={10}
                            circleWidth={20}
                            progressColor="#98cb51"
                            value={slider}
                            onChange={handleSliderChange}
                        />
                    </div>
                    <div className={classes.sliderRoot}>
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=> `R1: ${value}`}
                            defaultValue={selectedLight.r1 || 0}
                            aria-labelledby="vertical-slider"
                            onChange={handleChannelChange("r1")}
                        />
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=> `B1: ${value}`}
                            defaultValue={selectedLight.b1 || 0}
                            aria-labelledby="vertical-slider"
                            onChange={handleChannelChange("b1")}
                        />
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=> `W1: ${value}`}
                            defaultValue={selectedLight.w || 0}
                            aria-labelledby="vertical-slider"
                            onChange={handleChannelChange("w")}
                        />
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=> `R2: ${value}`}
                            defaultValue={selectedLight.r2 || 0}
                            aria-labelledby="vertical-slider"
                            onChange={handleChannelChange("r2")}
                        />
                        <Slider
                            orientation="vertical"
                            getAriaValueText={(value)=> `B2: ${value}`}
                            defaultValue={selectedLight.b2 || 0}
                            aria-labelledby="vertical-slider"
                            onChange={handleChannelChange("b2")}
                        />

                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

const mapStateToProps = state => ({
    groups: state.groups,
    lights: state.lights
});
export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(Lights)
