import _ from 'lodash';
import moment from 'moment';
import numbro from 'numbro';

class common {
    static pluralize(count, singleVariation, pluralVariation) {
        if (count === 1) {
            return singleVariation;
        }

        return pluralVariation;
    }

    static quellFn(fn) {
        return function(e, ...args) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            return fn(...args);
        };
    }

    static formatters = {
        asFullDate: val => {
            if (!val) {
                return '';
            }
            return moment(val).format('MM/DD/YYYY');
        },
        asTime: val => {
            if (!val) {
                return '';
            }
            return moment(val).format('h:mm');
        },
        asStatus: val => {
            if (!val) {
                return 'Draft';
            }
            switch (val) {
                case 0:
                    return 'Draft';
                case 1:
                    return 'Submitted';
                case 2:
                    return 'Deleted';
            }
            return '(?)';
        },
        asCurrency: val => {
            if (!val) {
                return numbro(0).format('$0,0.00');
            }
            return numbro(val).format('$0,0.00');
        },
        asInteger: val => {
            if (!val) {
                return numbro(0).format('0,0');
            }
            return numbro(val).format('0,0');
        },
        asDecimal: val => {
            if (!val) {
                return numbro(0).format('0,0.00');
            }
            return numbro(val).format('0,0.00');
        },
        asMonth: val => {
            if (!val) {
                return '';
            }
            const { months } = common.options;
            return _.find(months, x => x.value == parseInt(val)).label;
        },
        // asPrependZeros: (str, len, seperator) => {
        //     if (typeof str === 'number' || Number(str)) {
        //         str = str.toString();
        //         return len - str.length > 0
        //             ? new Array(len + 1 - str.length).join('0') + str
        //             : str;
        //     }
        //
        //     for (
        //         let i = 0, spl = str.split(seperator || ' ');
        //         i < spl.length;
        //         spl[i] =
        //             Number(spl[i]) && spl[i].length < len
        //                 ? PrependZeros(spl[i], len)
        //                 : spl[i],
        //             str = i == spl.length - 1 ? spl.join(seperator || ' ') : str,
        //             i++
        //     );
        //     return str;
        // },
    };

    static renderers = {};

    static calculations = {
        average: (di, dv) => {
            if (di && dv && dv > 0) {
                return parseFloat(di) / parseFloat(dv);
            }
        }
    };

    static messages = {
        saveServerError: 'Unable to save. A server error occured.',
        saveFormInvalid: 'Unable to save. Form is invalid',
        deleteServerError: 'Unable to delete. A server error occurred.',
        saveError: 'Unable to save.',
        validationRequired: 'Field is required.',
        validationInt: 'Must be a valid whole number',
        validationCurrency: 'Must be a valid currency amount',
        validationNumber: 'Must be a valid number',
        validationMonth: 'Must be a valid month',
        validationYear: 'Must be a valid year',
        loginFailed: 'Login attempt failed. Check your password and try again'
    };

    static options = {
        years: [
            { value: 2020, label: '2020' },
            { value: 2019, label: '2019' },
            { value: 2018, label: '2018' },
            { value: 2017, label: '2017' },
            { value: 2016, label: '2016' },
            { value: 2015, label: '2015' },
            { value: 2014, label: '2014' }
        ],
        quarters: [
            { value: 1, label: 'Q1' },
            { value: 2, label: 'Q2' },
            { value: 3, label: 'Q3' },
            { value: 4, label: 'Q4' }
        ],
        months: [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' }
        ],
        days: [
            {value: 0, label: "Sunday", Prefix: 'Su'},
            {value: 1, label: "Monday", Prefix: 'M'},
            {value: 2, label: "Tuesday", Prefix: 'T'},
            {value: 3, label: "Wednesday", Prefix: 'W'},
            {value: 4, label: "Thursday", Prefix: 'Th'},
            {value: 5, label: "Friday", Prefix: 'F'},
            {value: 6, label: "Saturday", Prefix: 'Sa'}
        ],
        status: [
            { value: '', label: 'All' },
            { value: '0', label: 'Draft' },
            { value: '1', label: 'Complete' }
        ]
    };

    static helpers = {
        isAdmin: role => {
            if (!role) {
                return false;
            }
            return _.includes(
                ['Admin'],
                role
            );
        },
        hexToString: hex => {
            var str = '';
            for (var n = 0; n < hex.length; n += 2) {
                str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
            }
            return str;
        }
    };
}

export default common;
