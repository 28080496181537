/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRegistration = /* GraphQL */ `
  mutation CreateRegistration($input: CreateRegistrationInput!) {
    createRegistration(input: $input) {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateRegistration = /* GraphQL */ `
  mutation UpdateRegistration($input: UpdateRegistrationInput!) {
    updateRegistration(input: $input) {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegistration = /* GraphQL */ `
  mutation DeleteRegistration($input: DeleteRegistrationInput!) {
    deleteRegistration(input: $input) {
      id
      name
      email
      company
      credentials {
        id
        username
        password
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCredentials = /* GraphQL */ `
  mutation CreateCredentials($input: CreateCredentialsInput!) {
    createCredentials(input: $input) {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCredentials = /* GraphQL */ `
  mutation UpdateCredentials($input: UpdateCredentialsInput!) {
    updateCredentials(input: $input) {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCredentials = /* GraphQL */ `
  mutation DeleteCredentials($input: DeleteCredentialsInput!) {
    deleteCredentials(input: $input) {
      id
      username
      password
      registration {
        id
        name
        email
        company
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMeshSettings = /* GraphQL */ `
  mutation CreateMeshSettings($input: CreateMeshSettingsInput!) {
    createMeshSettings(input: $input) {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMeshSettings = /* GraphQL */ `
  mutation UpdateMeshSettings($input: UpdateMeshSettingsInput!) {
    updateMeshSettings(input: $input) {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeshSettings = /* GraphQL */ `
  mutation DeleteMeshSettings($input: DeleteMeshSettingsInput!) {
    deleteMeshSettings(input: $input) {
      id
      name
      email
      company
      username
      password
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createResetCodes = /* GraphQL */ `
  mutation CreateResetCodes($input: CreateResetCodesInput!) {
    createResetCodes(input: $input) {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateResetCodes = /* GraphQL */ `
  mutation UpdateResetCodes($input: UpdateResetCodesInput!) {
    updateResetCodes(input: $input) {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteResetCodes = /* GraphQL */ `
  mutation DeleteResetCodes($input: DeleteResetCodesInput!) {
    deleteResetCodes(input: $input) {
      id
      email
      code
      expiration
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
