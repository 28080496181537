import * as constants from '../utils/constants';
import _ from 'lodash';
const initialState = {
    initScan: true,
    groups: [

    ],
    lights: [

    ],
    routines: [

    ]
};

export default function (state = initialState, action) {
    let groups = _.clone(state.groups);
    let lights = _.clone(state.lights);
    let routines = _.clone(state.routines);
    let edit = {};
    switch (action.type) {
        case constants.ADD_GROUP:
            groups.push(action.action)
            return {
                ...state,
                groups: groups
            };
        case constants.SET_INIT_SCAN:
            return {
                ...state,
                initScan: action.action
            };
        case constants.REMOVE_GROUP:
            _.remove(groups, function(n) {
                return n.id == action.action;
            });
            return {
                ...state,
                groups: groups
            };
        case constants.UPDATE_GROUP:
            groups = _.map(groups, function(a) {
                return a.id === action.action.id ? {...a, ...action.action} : a;
            });
            return {
                ...state,
                groups: groups
            };
        case constants.ADD_LIGHT:
            const index = lights.findIndex(x => x.id==action.action.id);
            if (index === -1){
                lights.push(action.action);
            }
            return {
                ...state,
                lights: lights
            };
        case constants.ADD_LIGHTS:
            _.each(action.action, (l)=> {
                console.log(l)
                const i = lights.findIndex(x => x.id==l.id);
                if (i === -1){
                    lights.push(l);
                }else{
                    // lights[i].r1 = l.r1;
                    // lights[i].b1 = l.b1;
                    // lights[i].w = l.w;
                    // lights[i].r2 = l.r2;
                    // lights[i].b2 = l.b2;
                    lights[i].brightness = l.brightness
                }
            });

            return {
                ...state,
                lights: lights
            };
        case constants.UPDATE_LIGHT:
            lights = _.map(lights, function(a) {
                return a.id === action.action.id ? {...a, ...action.action} : a;
            });
            return {
                ...state,
                lights: lights
            };
        case constants.REMOVE_LIGHT:
            _.remove(lights, function(n) {
                return n.id === action.action;
            });
            return {
                ...state,
                lights: lights
            };
        case constants.CLEAR_LIGHTS:
            var cleared = _.map(lights, function(a) {return {...a, ...{connectionStatus: 2, disabled: false}}});
            return {
                ...state,
                lights: cleared
            };
        case constants.RENAME_LIGHT:
            lights = _.map(lights, function(a) {
                return a.id === action.action.id ? {...a, name: action.action.name} : a;
            });
            return {
                ...state,
                lights: lights
            };
        case constants.TOGGLE_GROUP:
            var newGroups = _.map(groups, function(a) {
                return a.id === action.action.id ? {...a, state: action.action.state, disabled: true} : a;
            });
            return {
                ...state,
                groups: newGroups
            };
        case constants.TOGGLE_LIGHT:
            // lodash
            var newLights = _.map(lights, function(a) {
                return a.id === action.action.id ? {...a, state: action.action.state, disabled: true} : a;
            });
            return {
                ...state,
                lights: newLights
            };
        case constants.FADE_ON:
            // lodash
            var newLights = _.map(lights, function(a) {
                return a.id === action.action.id ? {...a, disabled: false} : a;
            });
            return {
                ...state,
                lights: newLights
            };
        case constants.FADE_OFF:
            // lodash
            var newLights = _.map(lights, function(a) {
                return a.id === action.action.id ? {...a, disabled: false} : a;
            });
            return {
                ...state,
                lights: newLights
            };
        case constants.GROUP_FADE_ON:
            // lodash
            var newGroups = _.map(groups, function(a) {
                return a.id === action.action.id ? {...a, disabled: false} : a;
            });
            return {
                ...state,
                groups: newGroups
            };
        case constants.GROUP_FADE_OFF:
            // lodash
            var newGroups = _.map(groups, function(a) {
                return a.id === action.action.id ? {...a, disabled: false} : a;
            });
            return {
                ...state,
                groups: newGroups
            };
        case constants.TOGGLE_ROUTINE:
            // lodash
            routines = _.map(routines, function(a) {
                return a.id === action.action.id ? {...a, state: action.action.state} : a;
            });
            return {
                ...state,
                routines
            };
        case constants.RENAME_ROUTINE:
            // lodash
            routines = _.map(routines, function(a) {
                return a.id === action.action.id ? {...a, name: action.action.name} : a;
            });
            return {
                ...state,
                routines
            };
        case constants.REMOVE_ROUTINE:
            // lodash
            _.remove(routines, function(n) {
                return n.id === action.action;
            });
            return {
                ...state,
                routines: routines
            };
        case constants.ADD_ROUTINE:
            // lodash
            routines.push(action.action);
            return {
                ...state,
                routines: routines
            };
        case constants.EDIT_ROUTINE:
            // lodash
            routines = _.map(routines, function(a) {
                return a.id === action.action.id ? {...a, ...action.action} : a;
            });
            return {
                ...state,
                routines: routines
            };
        case constants.TOGGLE_ALL:
            const toggleGroups = _.map(groups, function(a) {
                return {...a, state: action.action};
            });
            return {
                ...state,
                groups: toggleGroups
            };
        default:
            return state;
    }
}
