import React, {useEffect, useState} from 'react';
import Sidebar from './components/sidebar'
import {Drawer, Hidden, AppBar, Toolbar, IconButton, Typography, CssBaseline, Button} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useTheme} from '@material-ui/core/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import styles from './utils/styles';
import * as constants from './utils/constants'
import './App.css';

import Amplify, {PubSub, Auth} from 'aws-amplify';
import {AWSIoTProvider} from '@aws-amplify/pubsub/lib/Providers';
import {
    Authenticator,
    Greetings,
    SignIn,
    ConfirmSignIn,
    VerifyContact,
    ForgotPassword,
    RequireNewPassword
} from "aws-amplify-react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import routes from "./utils/routes";

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as actions from './redux/actions'
import _ from 'lodash';
import awsConfig from './aws-exports';

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [
//     localRedirectSignIn,
//     productionRedirectSignIn,
// ] = awsConfig.oauth.redirectSignIn.split(",");
//
// const [
//     localRedirectSignOut,
//     productionRedirectSignOut,
// ] = awsConfig.oauth.redirectSignOut.split(",");
//
// const updatedAwsConfig = {
//     ...awsConfig,
//     oauth: {
//         ...awsConfig.oauth,
//         redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//         redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//     }
// }

Amplify.configure(awsConfig);

Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_REGION,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
}));

const customTheme = createMuiTheme({
    typography: {
        fontFamily: [
            "'Nunito'",
            'sans-serif'
        ].join(', '),
    },
    palette: {
        primary: {
            main: '#98cb51'
        }
    }
});


function App(props) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [auth, setAuth] = useState({authState: '', authData: {}});
    const classes = styles();
    const theme = useTheme();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const setAuthState = (state, data) => {
        checkAuth();
        setAuth({authState: state, authData: data});
    };
    const signOut = async () => {
        await Auth.signOut();
        setAuth({authState: '', authData: {}});
    };
    const checkAuth = async () => {
        const session = await Auth.currentSession();
        const groups = session.accessToken.payload['cognito:groups'];
         console.log(groups)
        if (!_.some(groups, x=>x==='admin')) {
            await Auth.signOut();
            setAuth({authState: '', authData: {}});
        }
        // console.log(session.payload['cognito:groups']);
    }
    useEffect(() => {
        PubSub.subscribe(constants.LIGHT_ON_NOTIFY).subscribe({
            next: data => props.actions.onNotify(data),
            error: error => console.error(error),
            close: () => console.log('Done'),
        })
    }, [auth]);

    // useEffect(()=>{
    //     checkAuth()
    // }, []);


    const {authState, authData} = auth;
    const signedIn = authState === 'signedIn';
    const authComponents = [
        <SignIn/>,
        <RequireNewPassword/>,
        <ConfirmSignIn/>,
        <VerifyContact/>,
        <ForgotPassword/>
    ];
    if (signedIn) {
        return (
            <React.Fragment>
                <Authenticator
                    hideDefault={true}
                    children={authComponents}
                />
                <Router>
                    <ThemeProvider theme={customTheme}>
                        <div className={classes.root}>
                            <CssBaseline/>
                            <AppBar position="fixed" className={classes.appBar} color={"inherit"}>
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        className={classes.menuButton}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <div className={classes.toolbarImage}>
                                        <img src={"/img/Logo.png"} height={40}/>
                                    </div>
                                    {/*<div className={classes.grow}></div>*/}
                                    <div>
                                        <IconButton
                                            aria-label="show more"
                                            aria-haspopup="true"
                                            onClick={signOut}
                                            color="inherit"
                                        >
                                            <ExitToAppIcon/>
                                        </IconButton>
                                    </div>
                                </Toolbar>
                            </AppBar>
                            <nav className={classes.drawer} aria-label="mailbox folders">
                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <Hidden smUp implementation="css">
                                    <Drawer
                                        variant="temporary"
                                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}
                                    >
                                        <Sidebar items={routes} toggleDrawer={handleDrawerToggle}/>
                                    </Drawer>
                                </Hidden>
                                <Hidden xsDown implementation="css">
                                    <Drawer
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        variant="permanent"
                                        open
                                    >
                                        <Sidebar items={routes}/>
                                    </Drawer>
                                </Hidden>
                            </nav>
                            <main className={classes.content}>
                                <div className={classes.toolbar}/>
                                <Switch>
                                    {routes.map((route, index) => (
                                        // Render more <Route>s with the same paths as
                                        // above, but different components this time.
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            children={<route.component/>}
                                        />
                                    ))}
                                </Switch>
                            </main>
                        </div>
                    </ThemeProvider>
                </Router>
            </React.Fragment>
        );
    }

    return (
        <div className={classes.authHeader}>
            <div className={classes.authImage}>
                <img src={"/img/Logo_Full.png"} height={100}/>
            </div>
            <Authenticator
                hideDefault={true}
                onStateChange={setAuthState}
                children={authComponents}
            />
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(App);
